import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight: '#FFFFFF',
      neutralDark2: '#8C8A85',
      neutralDark1: '#313131',
      primaryLight: '#F2EEE8',
      primaryDark: '#D9D5CF',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'ivypresto-display', serif",
    paragraph: "'neue-haas-unica', sans-serif",
    langs: "'neue-haas-unica', sans-serif",
  },
}

export const chinese = {
  ...theme,
  fontFamily: {
    heading: "'source-han-serif-tw-subset', sans-serif",
    paragraph: "'source-han-serif-tw-subset', sans-serif",
    langs: "'neue-haas-unica', sans-serif",
  },
}

export type Styles = typeof styles

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralDark2};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.875rem;
    letter-spacing: 0.08em;
    line-height: 1.375rem;
    text-transform: uppercase;
    font-weight: 300;
  `,
  title: {
    xxl: css`
      font-family: ${theme.fontFamily.heading};
      font-size: 6rem;
      font-weight: lighter;
      line-height: 7.5rem;

      @media (max-width: 1199px) {
        font-size: 3rem;
        line-height: 4rem;
      }
    `,
    xl: css`
      font-family: ${theme.fontFamily.heading};
      font-size: 5rem;
      line-height: 5.5rem;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.625rem;
      }
    `,
    large: css`
      font-family: ${theme.fontFamily.heading};
      font-size: 3.875rem;
      line-height: 4.6875rem;
      font-weight: lighter;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.625rem;
      }
    `,
    medium: css`
      font-family: ${theme.fontFamily.heading};
      font-size: 3rem;
      line-height: 3.8rem;
      font-weight: lighter;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.625rem;
      }
    `,
    normal: css`
      font-family: ${theme.fontFamily.heading};
      font-size: 2.5rem;
      line-height: 3.375rem;
      font-weight: lighter;
    `,
    small: css`
      font-family: ${theme.fontFamily.heading};
      font-size: 2rem;
      line-height: 2.25rem;
    `,
    xs: css`
      font-family: ${theme.fontFamily.heading};
      font-size: 1.125rem;
      line-height: 1.75rem;

      @media (max-width: 1199px) {
        line-height: 2.25rem;
        letter-spacing: 0.002em;
      }
    `,
  },
  description: {
    medium: css`
      color: ${theme.colors.variants.neutralDark1};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.001em;
    `,
    small: css`
      color: ${theme.colors.variants.neutralDark2};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 0.875rem;
      line-height: 1.5rem;
      letter-spacing: 0.001em;
    `,
  },
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({
  children,
  pageContext,
}: Props) {
  let t

  switch (pageContext.languagePrefix) {
    case 'zh':
      t = chinese
      break

    default:
      t = theme
  }

  return (
    <EmotionThemeProvider theme={t}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
